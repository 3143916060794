<template>
  <div class="carhistory-box" v-if="history_car.length > 0">
    <h3 class="carhistory-h3">历史车牌</h3>
    <div class="history-car">
      <ul class="history-ul">
        <li v-for="(item,index) of history_car" :key="index" >
          <van-tag 
            color="#f5f5f5"
            text-color="#333"
            round
            closeable
            type="primary"
            class="ul-tag"
            @click="chooseHistoryCar(item)"
            closeable @close="deleteHistoryCar(item)">
            {{item.number}}</span>  
          </van-tag>
        </li>
      </ul>
      <div class="history-more" @click="historycar_show = true" v-if="history_car.length > 3">
        <van-icon name="arrow-down" class="more-icon" />
      </div>
    </div>
    <!-- <div class="history-complete" @click="historycar_show = true">
      历史记录
    </div> -->
    <!-- 历史记录弹窗 -->
    <van-dialog v-model="historycar_show" title="历史记录" confirmButtonText="关闭">
      <van-cell-group class="history-box" :border="false">
        <van-cell center v-for="(item,index) of history_car" :key="index" v-if="index >= 3" @click="chooseHistoryCar(item)">
          <template #title>
            <div>
              {{item.number}} <span v-if="item.name !== ''">({{item.name}})</span>
            </div>
          </template>
          <template #right-icon>
            <van-icon name="delete-o" size="20" @click.stop="deleteHistoryCar(item)" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Dialog } from 'vant';

export default {
  data() {
    return {
      istorycar_show:false, // 历史记录弹窗
      historycar_show:false,
    };
  },
  computed:{
    ...mapState({
      history_car:state => state.car.history_car,
    }),
  },
  methods: {
    // 点击历史记录
    chooseHistoryCar(item){
      // 传递参数给父级，用于其他数据处理
      this.$emit("sendCarData", item);
      this.historycar_show = false
    },
    // 点击删除历史记录
    deleteHistoryCar(item){
      Dialog.confirm({
        title: '标题',
        message: '确定删除',
      })
      .then(() => {
        this.$store.dispatch('car/removeCarHistory',item)
      })
      .catch(() => {
        // on cancel
      });
    },
  },
}
</script>
<style lang='scss'>
.carhistory-box{
  padding: 0; box-sizing: border-box; display: block; overflow: hidden;
  .carhistory-h3{
    font-size: 0.23rem;
    font-weight: normal;
    color: #999;
    display: block;
    overflow: hidden;
    margin: 0.3rem 0.3rem 0;
  }
  .history-complete{
    display: block;
    background: none;
    padding:0.2rem 0.3rem;
    text-align: right;
    color: #1989fa;
    font-size: 0.23rem;
    border: none;
  }
}
.history-car{
  width: 100%; height: auto; padding:0.2rem 0.3rem; box-sizing: border-box; display: flex; overflow: hidden; position: relative; align-items: center;
  .history-ul{
    padding: 0; display: block; overflow: hidden; box-sizing: border-box; flex: 1; height: 0.5rem;
    li{
      float: left; margin: 0 0.22rem 0.3rem 0 ;
      .ul-tag{
        height: 0.5rem;
        padding: 0 0.18rem;
        font-size: 0.22rem;
        line-height: normal;
      }
    }
  }
  .history-more{
    width: 0.6rem; display: block; overflow: hidden;
    .more-icon{
      font-size: 0.36rem; color: #888; display: block; float: right;
    }
  }
}
.history-box{
  max-height: 50vh; display: block; overflow: hidden; overflow-y: auto;
}
</style>